<template>
  <div class="worksVideos"
       v-infinite-scroll="load"
       infinite-scroll-distance="40"
       :infinite-scroll-disabled="scrollDisabled">
    <div class="worksVideos-main">
      <Tab @tabChang="tabChang"
           :tabList="tabList"
           :tabcurrent="tabcurrent">
      </Tab>
      <Serch @searchForm="searchForm"></Serch>
      <list :worksList="worksList"
            @examineWorks="examineWorks"></list>
    </div>
  </div>
</template>

<script>
import { defineComponent, defineAsyncComponent, toRefs, reactive, getCurrentInstance, onMounted } from 'vue'
const Tab = defineAsyncComponent(() => import('./component/tab.vue'))
const Serch = defineAsyncComponent(() => import('./component/serch.vue'))
const list = defineAsyncComponent(() => import('./component/list.vue'))
export default defineComponent({
  components: {
    Tab,
    Serch,
    list
  },

  setup (props, context) {
    const { proxy } = getCurrentInstance()
    const data = reactive({
      tabcurrent: '0',
      tabList: [
        { value: '0', label: '待审核' },
        { value: '1', label: '审核通过' },
        { value: '2', label: '审核未通过' }
      ],
      worksList: [],
      formData: {
        user_name: '',
        date: [],
        worksNmae: ''
      },
      pagination: {
        page: 1,
        limit: 20,
        total_count: 0
      },
      isFlag: true,
      scrollDisabled: true
    })
    onMounted(() => {
      getlist()
    })

    const load = () => {
      if (!data.isFlag) {
        return
      }
      data.pagination.page = +data.pagination.page + 1
      getlist()
    }

    const tabChang = (val) => {
      data.tabcurrent = val
      data.worksList = []
      data.pagination.page = 1
      data.isFlag = true
      getlist()
    }

    const searchForm = (obj) => {
      data.formData = obj
      data.pagination.page = 1
      data.worksList = []
      getlist()
    }

    const examineWorks = () => {
      data.pagination.page = 1
      data.worksList = []
      getlist()
    }

    const getlist = async () => {
      try {
        let startTime = ''
        let endTime = ''
        if (data.formData.date && data.formData.date[0]) {
          startTime = proxy.$moment(data.formData.date[0]).unix()
          endTime = proxy.$moment(data.formData.date[1]).unix()
          //修正结束时间
          // if (startTime === endTime) {
          endTime = endTime + 86399
          // }
        }
        const sd = await proxy.$api.getWorksList({
          start_time: startTime,
          end_time: endTime,
          user_name: data.formData.user_name || '',
          title: data.formData.work_name || '',
          page: data.pagination.page,
          limit: data.pagination.limit,
          type: 1,
          status: +data.tabcurrent
        })
        data.worksList = data.worksList.concat(sd.data.list)
        data.scrollDisabled = false
        if (sd.data.list.length < 10) {
          data.isFlag = false
          data.scrollDisabled = true
        }
        data.pagination = sd.data.pagination
      } catch (err) {
        data.scrollDisabled = true
        return proxy.$notify({
          title: '系统通知',
          message: err,
          type: 'error'
        })
      }
    }
    return {
      ...toRefs(data),
      getlist,
      load,
      tabChang,
      examineWorks,
      searchForm
    }
  }
})
</script>

<style lang="scss" scoped>
.worksVideos {
  padding: 10px;
  height: calc(100vh - 100px);
  overflow: auto;
  .worksVideos-main {
    background-color: #fff;
  }
}
</style>
